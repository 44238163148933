import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/runner/work/zap-onboard-api/zap-onboard-api/services-unmanaged/zap-onboard-home-page/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { SupportFooter } from '../../../components/SupportFooter';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "certification-basics-"
    }}>{`Certification Basics 🗂`}</h1>
    <p>{`Canyou allows you to manage licences and certifications for a team.`}</p>
    <p>{`The setup process involves the following steps:`}</p>
    <ol>
      <li parentName="ol">{`Create a Certification ⚡️`}</li>
      <li parentName="ol">{`Assigning the certification as a requirement of a Position (group of users) or a single user 🧑‍🍳`}</li>
      <li parentName="ol">{`Reviewing and approving the certifications 🧑‍💻`}</li>
    </ol>
    <p>{`The following pages outline how all this works.`}</p>

    <SupportFooter linkTo="/support/certifications/adding-certifications" linkText="Let's get started" mdxType="SupportFooter" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      